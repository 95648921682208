<template>
  <div class="md:hidden">
    <!--List view Subcategories mobile-->
    <template v-if="categoriesLayout === '1'">
      <div
        class="px-[1.7rem]"
        v-for="(subcategory, i) in subcategories"
        :key="i"
      >
        <div @click="toggleOpen(i)" class="border-b border-[#C1C1C1] font-bold">
          <div
            @click="handleSubCategoryClick(subcategory)"
            class="flex cursor-pointer items-center justify-between px-[1.7rem] py-5"
          >
            <div class="h2 block cursor-pointer">{{ subcategory.title }}</div>
            <IconsArrowUpIcon
              v-if="subcategory.children.length > 0"
              :class="{
                'rotate-0': activeIndex === i,
                'rotate-180': activeIndex !== i,
              }"
              class="transform transition ease-in-out"
            />
          </div>
          <transition name="slide" mode="out-in">
            <div
              v-if="activeIndex === i && subcategory.children.length > 0"
              class="w-full bg-[#E9E9E9] p-0"
              key="collection"
            >
              <div
                class="px-[1.7rem]"
                v-for="(collection, j) in subcategory.children"
                :key="collection.id"
                @click="handleCollectionCLick(collection)"
              >
                <NuxtLink
                  :to="{
                    path: $features?.home ? paths.products.index : paths.home,
                    query: {
                      categories: category?.id,
                      sub_categories: subcategory?.id,
                      collections: collection.id,
                    },
                  }"
                  class="display-2 block cursor-pointer px-4 py-3 hover:text-[#c03054]"
                  :class="{
                    'border-b border-[#C1C1C1]':
                      j !== subcategory.children.length - 1,
                  }"
                >
                  {{ collection.title }}
                </NuxtLink>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        :class="{ 'border-none': selectedCategory?.children.length == 0 }"
        class="flex cursor-pointer items-center justify-between px-[1.7rem] py-5 font-bold"
      >
        <NuxtLink
          @click="$emit('close'), handleViewAllCLick(selectedCategory)"
          :to="{
            path: $features?.home ? paths.products.index : paths.home,
            query: { categories: selectedCategory?.id },
          }"
          class="h2 block cursor-pointer px-[1.7rem] hover:text-[#c03054]"
        >
          {{ $t("informative.header.titles.view_all") }}
          {{ selectedCategory?.title }}
        </NuxtLink>
      </div>
    </template>
    <!--Grid view Subcategories mobile-->
    <template v-if="categoriesLayout === '2'">
      <div class="gap-l-[18px] gap-x-50 mt-4 grid grid-cols-2 gap-y-3 px-0">
        <div
          v-for="(subcategory, i) in subcategories"
          :key="subcategory.id"
          class=""
        >
          <NuxtLink
            @click="
              $parent?.$emit('close'), handleSubCategoryClick(subcategory)
            "
            :to="{
              path: $features?.home ? paths.products.index : paths.home,
              query: {
                sub_categories: subcategory.id,
              },
            }"
            :class="{ 'border-none': i == 0 }"
            class="flex cursor-pointer flex-col items-center border-[#C1C1C1] font-bold"
          >
            <CommonUImg
              class="h-[120px] w-[156px]"
              :src="subcategory.category_image || ''"
              :alt="subcategory.title"
            />
            <span class="display-2 mt-4 block cursor-pointer">
              {{ subcategory.title }}
            </span>
          </NuxtLink>
        </div>
        <div class="flex flex-col items-center">
          <NuxtLink
            @click="$parent?.$emit('close')"
            :to="{
              path: $features?.home ? paths.products.index : paths.home,
              query: { categories: selectedCategory?.id },
            }"
            class="flex h-[120px] w-[156px] cursor-pointer flex-col items-center justify-center bg-[#F5F5F5] py-5 font-bold"
          >
            <span class="h2 mx-[42px] block cursor-pointer text-center">
              {{ $t("informative.header.titles.view_all") }}
              {{ selectedCategory?.title }}
            </span>
          </NuxtLink>
        </div>
      </div>
    </template>
    <!--Small Card Subcategories mobile-->
    <template v-if="categoriesLayout === '3'">
      <div class="px-[1.7rem]">
        <div v-for="(subcategory, i) in subcategories" :key="subcategory.id">
          <NuxtLink
            @click="
              $parent?.$emit('close'), handleSubCategoryClick(subcategory)
            "
            class="flex cursor-pointer items-center border-[#C1C1C1] py-5 font-bold"
            :to="{
              path: $features?.home ? paths.products.index : paths.home,
              query: {
                sub_categories: subcategory.id,
              },
            }"
            :class="[
              { 'border-none': i == 0 },
              { 'border-t-[0.5px] border-[#C1C1C1] ': i != 0 },
            ]"
          >
            <CommonUImg
              class="h-[80px] w-[80px] rounded-lg"
              :src="subcategory.category_image || ''"
              :alt="subcategory.title"
            />
            <span class="subcategory-title h3 ml-5 block cursor-pointer">{{
              subcategory.title
            }}</span>
          </NuxtLink>
        </div>
        <div>
          <NuxtLink
            @click="$parent?.$emit('close')"
            :to="{
              path: $features?.home ? paths.products.index : paths.home,
              query: { categories: selectedCategory?.id },
            }"
            class="flex cursor-pointer items-center justify-between border-t-[0.5px] border-[#C1C1C1] py-5 font-bold"
          >
            <div class="flex items-center">
              <CommonUImg
                class="h-[80px] w-[80px] rounded-lg"
                v-if="
                  selectedCategory?.category_image ||
                  selectedCategory?.category_image != ''
                "
                :src="selectedCategory?.category_image"
                :alt="selectedCategory?.title"
              />
              <span class="subcategory-title h2 ml-5 block cursor-pointer"
                >{{ $t("informative.header.titles.view_all") }}
                {{ selectedCategory?.title }}</span
              >
            </div>
          </NuxtLink>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
import { Subcategory } from "~/types/InformativePages/Header/CategoryBundleBrand";
import { Category } from "~/types/ProductManagement/Categories";
defineProps({
  subcategories: Array as PropType<Subcategory[]>,
  categoriesLayout: String,
  selectedCategory: Object as PropType<Category>,
});
const emit = defineEmits([
  "close",
  "sub-category-selected",
  "collection-selected",
  "view-all-selected",
]);
const router = useRouter();
const activeIndex = ref<number | null>(null);
const filterStore = useFiltersStore();
const route = useRoute();

const toggleOpen = (index: number) => {
  activeIndex.value = activeIndex.value === index ? null : index;
};

const handleSubCategoryClick = (subcategory: any) => {
  const currentPath = route.path;
  emit("sub-category-selected", subcategory);
  if (!subcategory.children || subcategory.children.length === 0) {
    router.push({
      path: useNuxtApp().$features?.home ? paths.products.index : paths.home,
      query: {
        sub_categories: subcategory.id,
      },
    });
    emit("close");
  }
  if (currentPath === "/products" || !useNuxtApp().$features?.home) {
    filterStore.clearAllFilters();
    filterStore.applyFilter("sub_categories", subcategory?.id);
  }
};
const handleCollectionCLick = (collection: any) => {
  emit("collection-selected", collection);
  if (!collection.children || collection.children.length === 0) {
    router.push({
      path: useNuxtApp().$features?.home ? paths.products.index : paths.home,
      query: {
        collections: collection.id,
      },
    });
    emit("close");
  }
};

const handleViewAllCLick = (selectedCategory: any) => {
  emit("view-all-selected", selectedCategory);
  router.push({
    path: useNuxtApp().$features?.home ? paths.products.index : paths.home,
    query: {
      categories: selectedCategory.id,
    },
  });
};
</script>
