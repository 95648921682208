import { useCategoriesStore } from "~/store/Features/ProductManagement/categoriesStore";
import {
  Brand,
  Bundle,
} from "~/types/InformativePages/Header/CategoryBundleBrand";
import { Category } from "~/types/ProductManagement/Categories";

export default function useHeaderMenuInfo() {
  const categories = ref<Category[] | undefined>();
  const brands = ref<Brand[]>([]);
  const bundles = ref<Bundle[]>([]);
  const isLoading = ref(true);

  const { fetchCategories } = useCategoriesStore();
  const nuxtApp = useNuxtApp();

  const getCategories = async () => {
    try {
      isLoading.value = true;

      categories.value = await fetchCategories({ include_media: true });
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      isLoading.value = false;
    }
  };

  const getBrands = async () => {
    if (nuxtApp.$features?.brands) {
      try {
        isLoading.value = true;
        const { res } = await useApi<{ data: Brand[] }>(`/api/v1/brands`);
        if (res?.data) brands.value = res.data;
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        isLoading.value = false;
      }
    }
  };

  const getBundles = async () => {
    if (useNuxtApp().$features?.bundles) {
      try {
        isLoading.value = true;
        const { res } = await useApi<{ data: Bundle[] }>(`/api/v1/bundles`);
        if (res?.data) bundles.value = res.data;
      } catch (error) {
        console.error("Error fetching bundles:", error);
      } finally {
        isLoading.value = false;
      }
    }
  };

  return {
    categories,
    brands,
    bundles,
    isLoading,
    getBrands,
    getCategories,
    getBundles,
  };
}
