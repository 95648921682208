import { defineStore } from "pinia";
import { Product } from "~/types/InformativePages/Header/Searchbar";
import { LocationQueryValue } from "vue-router";
import { BaseResponse } from "~/types/Common/APIs";

export const useSearchStore = defineStore("search-store", () => {
  const suggestions = ref([] as string[]);
  const matches = ref<Product[]>([]);
  const showMenu = ref(false);
  const recents = ref<string[]>([]);

  const getSuggestions = async (
    value: string | LocationQueryValue[] | null,
  ) => {
    const { res } = await useApi<BaseResponse<string[]>>(
      `/api/v1/products-autocomplete?search=${value}`,
    );
    suggestions.value =
      res?.data.slice(0, 6).map((item: any) => String(item)) ?? [];
  };

  const addToRecents = (title: string) => {
    if (!recents.value.includes(title)) {
      recents.value.push(title);
    }
  };

  const clearRecents = () => {
    recents.value = [];
  };

  const getMatches = async (value: string | LocationQueryValue[] | null) => {
    const { res } = await useApi<BaseResponse<Product[]>>(
      `/api/v2/products?per_page=6&filter[v2_search]=${value}`,
    );
    matches.value = res?.data ?? [];
  };

  const getSearchData = async (value: string | LocationQueryValue[] | null) => {
    await getSuggestions(value);
    await getMatches(value);
    if (suggestions.value.length == 0 && matches.value?.length == 0) {
      showMenu.value = false;
    }
  };

  return {
    matches,
    suggestions,
    showMenu,
    recents,
    getSearchData,
    addToRecents,
    clearRecents,
  };
});
