import { BaseResponse } from "~/types/Common/APIs";
import { Page } from "~/types/Footer/HelpPages";

export const useHelpPages = () => {
  const pagesInfo = ref<Page[]>([]);

  const fetchPages = async () => {
    try {
      const { res } = await useApi<BaseResponse<Page[]>>(`/api/v1/pages`);
      if (res?.data) pagesInfo.value = res.data;
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const helpSectionPages = computed(() => {
    return pagesInfo.value.filter((page: Page) => page.key !== "about-us");
  });
  fetchPages();

  return {
    helpSectionPages,
  };
};
