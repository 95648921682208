<template>
  <div
    class="flex flex-col items-center justify-between bg-[#F0F0F0] px-2 py-8 md:flex-row md:px-[3.125rem] md:py-6 md:pb-8"
  >
    <div v-if="$appConfig?.brand_name" class="hidden md:my-0 md:block">
      &copy; {{ $appConfig.app_name }}
      {{ $t("footer.copyrights.reserved_rights") }}
    </div>

    <PaymentSponsors v-if="version == 'main'" />
    <div
      class="flex-cols-2 grid w-[60%] grid-cols-2 gap-4 md:hidden"
      v-if="version != 'main'"
    >
      <div
        v-for="(page, index) in helpSectionPages.slice(
          0,
          Math.ceil(helpSectionPages.length / 2),
        )"
        :key="index"
      >
        <NuxtLink
          class="my-2 flex flex-wrap pr-0 text-sm md:w-auto md:px-6"
          :to="getPathName(page.url)"
          >{{ page.title }}</NuxtLink
        >
      </div>
      <div
        v-for="(page, index) in helpSectionPages.slice(
          Math.ceil(helpSectionPages.length / 2),
        )"
        :key="index"
      >
        <NuxtLink
          class="my-2 flex flex-wrap pr-0 text-sm md:w-auto md:px-6"
          :to="getPathName(page.url)"
          >{{ page.title }}</NuxtLink
        >
      </div>
    </div>
    <div
      v-if="$appConfig?.brand_name"
      class="display-2 my-6 text-sm md:my-0 md:hidden"
    >
      &copy; {{ $appConfig.app_name }}
      {{ $t("footer.copyrights.reserved_rights") }}
    </div>
    <div
      class="flex flex-wrap items-center justify-center"
      v-if="version != 'main'"
    >
      <div class="hidden md:block">
        <NuxtLink
          class="display-2 my-2 w-[50%] px-3 text-right text-sm md:w-auto md:px-6 md:text-center"
          v-for="(page, index) in helpSectionPages"
          :key="index"
          :class="
            index != helpSectionPages?.length - 1
              ? 'border-r border-[#C1C1C1]'
              : ''
          "
          :to="getPathName(page.url)"
          >{{ page.title }}</NuxtLink
        >
      </div>
    </div>
    <div class="flex items-center gap-2">
      <div v-if="$appConfig?.show_hbm_credits" class="display-2 text-sm">
        &copy; {{ $t("footer.copyrights.powered_by") }}
      </div>
      <NuxtLink to="https://hbm.studio/" target="_blank">
        <img
          v-if="$appConfig?.show_hbm_credits"
          src="@/assets/icons/hbm.svg"
          alt="HBM Logo"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import PaymentSponsors from "./PaymentSponsors.vue";
import type { LayoutVersion } from "~/types/Common/LayoutVersion";
import { useHelpPages } from "~/composables/features/Footer/useHelpPages";

defineProps({
  version: String as PropType<LayoutVersion>,
});

const { helpSectionPages } = useHelpPages();

const getPathName = (url: string): string => new URL(url).pathname;
</script>
