<template>
  <div v-if="matches" class="title">
    {{ $t("informative.header.titles.pssible_items_matches") }}
  </div>
  <div
    id="product-details"
    v-for="(product, i) in matches"
    :key="i"
    v-show="i < 3"
  >
    <hr v-if="i !== 0" class="mt-4" />
    <NuxtLink
      :to="'/products/' + product.id"
      class="mt-4 flex cursor-pointer items-center"
      @click="handleClick(product.title)"
    >
      <div class="mr-4 h-[3.75rem] w-[3.75rem] flex-shrink-0">
        <img
          class="h-full w-full object-contain"
          :src="product.featured_image"
          :alt="product.title"
        />
      </div>
      <div class="flex h-[3.5rem] flex-col justify-between">
        <div class="h2 line-clamp-1">{{ product.title }}</div>
        <div class="h3">{{ product.price }}</div>
      </div>
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import { Product } from "~/types/InformativePages/Header/Searchbar";
defineProps<{ matches: Product[] | null }>();
const emit = defineEmits(["add-to-recents"]);

const handleClick = (title: string) => {
  emit("add-to-recents", title);
};
</script>
