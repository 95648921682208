<template>
  <div class="search">
    <div
      id="search-bar"
      class="search-bar"
      :class="
        !nuxtApp.$features?.categories ? 'w-full' : 'mx-auto w-full lg:w-4/5'
      "
    >
      <img src="@/assets/icons/search.svg" alt="Search Icon" />
      <div class="flex h-full w-full justify-between">
        <input
          v-on:keyup.enter="onEnter"
          @click="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keydown="onFocus"
          v-model="searchQuery"
          type="text"
          :placeholder="$t('informative.header.titles.search')"
          class="search-input"
        />
        <IconsXIcon
          v-if="searchQuery !== null && searchQuery !== ''"
          @click="onClear"
          class="cursor-pointer"
        />
        <div
          v-if="
            searchStore.showMenu &&
            searchStore.suggestions &&
            searchStore.matches
          "
          class="search-menu"
        >
          <div v-if="searchQuery" class="justify-between md:flex">
            <SuggestionsMenu
              :suggestions="searchStore.suggestions"
              @add-to-recents="searchStore.addToRecents"
            />
            <div id="matches" class="section mt-8 md:mt-0">
              <MatchesMenu
                :matches="searchStore.matches"
                @add-to-recents="searchStore.addToRecents"
              />
            </div>
          </div>
          <div v-else>
            <div class="recents">
              <div class="title">
                {{ $t("informative.header.titles.recent_search") }}
              </div>
              <div @click="searchStore.clearRecents" class="clear-recents">
                {{ $t("informative.header.titles.clear") }}
              </div>
            </div>
            <div
              v-for="recent in searchStore.recents"
              :key="recent"
              class="h3 mt-5 cursor-pointer"
            >
              <NuxtLink
                :to="`/products?search=${recent}`"
                class="mt-4 flex cursor-pointer items-center"
              >
                {{ recent }}
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MatchesMenu from "./MatchesMenu.vue";
import SuggestionsMenu from "./SuggestionsMenu.vue";
import { useSearchStore } from "~/store/Features/Header/searchStore";
import paths from "~/constants/paths";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";

const filterStore = useFiltersStore();
const searchStore = useSearchStore();

const route = useRoute();
const router = useRouter();
const nuxtApp = useNuxtApp();
const searchQuery: Ref<string> = ref<string>(
  route.query.search ? (String(route.query.search) as string) : "",
);

let dataTimeout: NodeJS.Timeout | null = null;

watch(
  () => route.query,
  (newQuery, oldQuery) => {
    if (!newQuery.search && oldQuery.search) {
      searchQuery.value = "";
    } else if (typeof newQuery.search === "string") {
      searchQuery.value = newQuery.search;
    }
  },
);

const debouncedData = (value: string) => {
  clearTimeout(dataTimeout!);
  dataTimeout = setTimeout(() => {
    searchStore.getSearchData(value);
  }, 500);
};

const onClear = () => {
  filterStore.clearAllFilters();

  searchQuery.value = "";
  if (nuxtApp.$features?.home && searchQuery.value === "") {
    if (router.currentRoute.value.path === paths.products.index) {
      router.push({
        path: paths.products.index,
        query: {},
      });
    }
  } else if (router.currentRoute.value.path === paths.home) {
    router.push({
      path: paths.home,
      query: {},
    });
  }
};

const onFocus = () => {
  if (searchStore.recents.length > 0) {
    searchStore.showMenu = true;
  }
  if (
    searchStore.matches?.length === 0 &&
    searchStore.suggestions?.length === 0
  ) {
    searchStore.showMenu = false;
  }
};

const onBlur = () => {
  setTimeout(() => {
    searchStore.showMenu = false;
  }, 200);
};

const onEnter = () => {
  router.push({
    path: nuxtApp.$features?.home ? paths.products.index : paths.home,
    query: { search: searchQuery.value },
  });
  searchStore.showMenu = false;
  searchStore.matches = null;
  searchStore.suggestions = [];
  searchStore.addToRecents(searchQuery.value);
};

const onInput = (event: Event) => {
  if ((event.target as HTMLInputElement).value == "") {
    searchStore.showMenu = false;
  }
  searchStore.showMenu = true;
  const target = event.target as HTMLInputElement;
  debouncedData(target.value);
};
</script>
